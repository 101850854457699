/* eslint-disable */

import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { saveCurrentProject, saveWorkflows } from '../../data/actions/userInterface';
import { makeStyles } from '@material-ui/core/styles';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
import InputAdornment from '@material-ui/core/InputAdornment';
import { red, yellow, grey, white } from '@material-ui/core/colors';
import { Button, Chip } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Person from '@material-ui/icons/Person';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake'; // see https://pdfmake.github.io/
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import * as constants from '../../constants/app';

import { DroppableProjectCardList } from '../project_overview/DroppableProjectCardList';
import { DroppableWorkflowCardList } from '../project_overview/DroppableWorkflowCardList';
import { DroppableAssignmentsList } from '../project_overview/DroppableAssignmentsList';
import { ContentWrapper } from '../styled_components/ContentWrapper';
import OverLimitDialog from '../OverLimitDialog';
import OnboardingDialog from '../OnboardingDialog';
import GroupNameDialog from '../GroupNameDialog';
import { DateRange } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import { AddButton } from '../styled_components/AddButton';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import { PriorityIcon } from '../Icons/PriorityIcon';
import { InfoButton } from '../styled_components/InfoButton';
import { useTheme } from '@material-ui/styles';

import {
  SupervisorAccount,
  Lock,
  Event,
  AccountTree,
  Assignment,
  DoubleArrow,
  Add,
} from '@material-ui/icons';
import { Heading } from '../styled_components/Heading';
import { Row } from '../styled_components/Row';
import { TeamModal } from './TeamModal';
import { InputField } from '../styled_components/InputField';
import * as utils from '../../utils/generalUtilities.js';
import * as USER from '../../constants/user';
import {
  dispatchUpdateViewState,
  handleMenu,
  updateViewState,
  dispatchSetGroup,
} from '../../data/actions/userInterface';
import { orderProjects } from '../../data/actions/firebase/userProjects';
import * as NAV from '../../constants/navigation';
import { Fragment } from 'react';
import { saveAssignments } from '../../data/actions/firebase/userAssignments.js';
import { dispatchUpdateUsibilityReport } from '../../data/actions/app';
const assignedToAvatarSize = 25;

let scrollTimer;

const dashboardStyles = makeStyles(theme => ({
  rootContainer: {
    display: 'block',
    paddingTop: '20px',
    paddingLeft: '15px',
    height: window.innerHeight,
    backgroundColor: theme.palette.secondary.faint,
  },
  parentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  },
  leftSide: {
    flex: 3,
    padding: 10,
    paddingLeft: 0,
    height: window.innerHeight,
    // background: 'yellow'
  },
  rightSide: {
    flex: 2.5,
    padding: 20,
    height: 'calc(100vh - 200px)',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: '#888',
    background: 'white',
    overflowY: 'hidden',
  },
  naTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'stretch',
  },
  clipButton: {
    marginTop: '-10px',
  },
  absButton: {
    position: 'absolute', // note parent is position: 'relative' to make this abs rel to parent
    right: 15,
    top: 25,
    width: 60,
    height: 30,
    // border: '1px solid #'
  },
  statusButtonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: 285,
    // marginLeft: 70,
    // marginRight: 50,
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 10,
    marginRight: 10,
    paddingBottom: 10,
    borderBottom: '1px solid #BBB',
  },
  projectsContainer: {
    display: 'block',
    overflowY: 'scroll',
    paddingLeft: '0px',
    paddingRight: '0px',
    height: window.innerHeight * 0.8,
    // backgroundColor: theme.palette.secondary.faint,
  },
  assignmentListContainer: {
    display: 'flex',
    // flex: 0,
    flexDirection: 'column',
    alignSelf: 'stretch',
    marginTop: 10,
    marginBottom: 30,
    paddingLeft: 20,
    paddingRight: 0,
    height: '100vh',
    // height: '90%',
    // backgroundColor: 'yellow'
  },
  projectListContainer: {
    flex: 1,
    flexDirection: 'column',
    alignSelf: 'stretch',
    marginTop: 10,
    marginBottom: 30,
    paddingLeft: 60,
    paddingRight: 0,
    // backgroundColor: 'yellow'
  },
  urProjectRow: {
    // flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  alertContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: '2px',
    marginBottom: '2px',
  },
  projectRow: {
    // flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignSelf: 'center',
  },
  addButtonContainer: {
    flex: 0,
    alignSelf: 'flex-start',
    marginLeft: 25,
    marginTop: 4,
    height: 30,
    width: 30,
  },
  infoButtonContainer: {
    flex: 0,
    alignSelf: 'flex-start',
    marginLeft: 25,
    marginTop: 4,
    height: 26,
    width: 26,
  },
  addButton: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.light,
    margin: '0 5px',
    '&:hover': {
      background: theme.palette.secondary.main,
      color: theme.palette.secondary.light,
    },
    boxShadow: 1,
  },
  infoButton: {
    background: theme.palette.secondary.menu,
    color: theme.palette.secondary.light,
    margin: '0 5px',
    '&:hover': {
      background: theme.palette.secondary.main,
      color: theme.palette.secondary.light,
    },
    boxShadow: 1,
  },
  dueIcon: {
    fontSize: '1.2rem',
    marginLeft: '0px',
    width: '40px',
  },
  dueText: {
    fontSize: '.9rem',
    marginLeft: '0px',
    marginBottom: '1px',
    width: '140px',
    color: '#555',
  },
  blankDueText: {
    fontSize: '.9rem',
    marginLeft: '0px',
    marginBottom: '1px',
    width: '180px',
    color: '#555',
  },
  alertComponentText: {
    flex: 1,
    fontSize: '1.1rem',
    marginLeft: '0px',
    marginTop: '-2px',
    marginBottom: '5px',
    // fontWeight: 600,
    color: '#555',
    cursor: 'pointer',
    '&:hover': {
      // background: theme.palette.secondary.main,
      color: '#3c8cba',
      textDecoration: 'underline',
    },
  },
  reportTitle: {
    alignSelf: 'center',
    fontSize: '1.4rem',
    // marginLeft: '40px',
    marginTop: '5px',
    marginBottom: '10px',
    fontWeight: 600,
    color: '#666',
  },
  assignSubTitle: {
    alignSelf: 'center',
    fontSize: '1rem',
    marginLeft: '40px',
    marginTop: '-3px',
    marginBottom: '10px',
    fontWeight: 500,
    color: '#666',
  },
  reportSubTitle: {
    alignSelf: 'center',
    fontSize: '1rem',
    marginLeft: '0px',
    marginTop: '5px',
    marginBottom: '10px',
    fontWeight: 600,
    color: '#6164C9',
  },
  reportlist: {
    marginLeft: '0px',
    marginTop: 20,
    overflowY: 'scroll',
  },
  reportButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginBottom: 5,
    minWidth: 70,
    color: '#444',
    // marginLeft: 50,
    // marginRight: 50
  },
  enabledReport: {
    display: 'flex',
    alignSelf: 'center',
    marginBottom: 5,
    width: '50%',
    color: '#444',
    textTransform: 'none',
    borderTop: '3px solid #ddd;',
    borderLeft: '3px solid #ddd;',
    borderRight: '3px solid #ddd;',
    // marginLeft: 50,
    // marginRight: 50
  },
  disabledReport: {
    display: 'flex',
    alignSelf: 'center',
    marginBottom: 5,
    width: '50%',
    color: '#888',
    textTransform: 'none',
    backgroundColor: '#ddd',
    // marginLeft: 50,
    // marginRight: 50
  },
  attentionButtons: {
    display: 'flex',
    alignSelf: 'center',
    marginBottom: 5,
    minWidth: 70,
    // marginLeft: 50,
    // marginRight: 50
  },
  attentionButton1: {
    flex: 1,
    minWidth: 60,
  },

  statusFilterButton: {
    flex: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: 'white',
    fontSize: 14,
    fontWeight: 700,
    padding: '1px 8px 1px 8px',
    minWidth: '100px',
    // height: '23px',
    border: '1px solid #CCC',
    borderRadius: '12px',
  },
  statusFilterButtonText: {
    flex: 0,
    color: 'white',
    fontSize: 15,
    fontWeight: 700,
    alignSelf: 'center',
    alignText: 'center',
  },
  notAssignedTo: {
    color: '#A0A0A040',
  },
  assignedToAvatarText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 700,
    alignSelf: 'center',
  },
  assignedToAvatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: assignedToAvatarSize / 2,
    height: assignedToAvatarSize,
    width: assignedToAvatarSize,
  },
  attentionMessage: {
    // margin: theme.spacing(2),
    color: '#888',
  },
  loadingIndicator: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 200,
    // backgroundColor: 'yellow'
  },
  pdfButton: {
    marginTop: '-7px',
    color: '#6164C9',
    padding: '0px',
    // backgroundColor: 'yellow'
  },
}));

const Dashboard = ({
  user,
  handleProjectInfoChange,
  projects,
  workflows,
  viewState,
  dispatchUpdateViewState,
  saveWorkflows,
  handleMenu,
  usibilityReport,
  assignments,
  dispatchUpdateUsibilityReport,
  userPrefs,
  updateViewState,
  dispatchSetGroup,
  group,
  firebase,
  saveAssignments,
}) => {
  const classes = dashboardStyles();
  const theme = useTheme();
  const [overLimit, setOverLimit] = useState(null);
  const listContainerRef = useRef(null);
  const [assignmentListHeight, setAssignmentListHeight] = useState(null);
  const [reportTab, setReportTab] = useState('Assignments');
  const [clipboardTootip, setClipboardTootip] = useState('copy to clipboard');
  const [onboardingDialog, setOnboardingDialog] = useState(
    Boolean(userPrefs.onboarding && userPrefs.onboarding[USER.ONBOARDING_DASHBOARD_DIALOG]),
  );

  orderProjects(projects);
  const keyDownHandler = event => {
    if (
      event.key === 'Tab' &&
      !event.metaKey &&
      !event.ctrlKey &&
      !event.shiftKey &&
      viewState.currentProjectType === NAV.TYPE_WORKFLOW
    ) {
      handleMenu(`workflow.${viewState.currentWorkflowSetId}.${viewState.currentWorkflowIndex}.Indent`);
    }
    if (
      event.key === 'Tab' &&
      !event.metaKey &&
      !event.ctrlKey &&
      event.shiftKey &&
      viewState.currentProjectType === NAV.TYPE_WORKFLOW
    ) {
      handleMenu(`workflow.${viewState.currentWorkflowSetId}.${viewState.currentWorkflowIndex}.Outdent`);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler);

    // returned function will be called on component unmount
    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };
  }, [viewState]);

  // if (!usibilityReport) {
  //   dispatchUpdateUsibilityReport();
  //   return null;
  // }

  const onDragAssignmentEnd = result => {
    // // dropped nowhere
    // if (!result.destination) {
    //   return;
    // }
    // const source = result.source;
    // const destination = result.destination;
    // if (source.droppableId !== destination.droppableId) {
    //   return; // block cross list dropping
    // }
    // // did not move anywhere - can bail early
    // if (source.droppableId === destination.droppableId && source.index === destination.index) {
    //   return;
    // }
    // const sourceProj = projects[source.index];
    // projects.splice(source.index, 1);
    // projects.splice(destination.index, 0, sourceProj);
    // this order is not persisted between reloads yet
  };
  const onDragEnd = result => {
    // dropped nowhere
    if (!result.destination) {
      return;
    }
    const source = result.source;
    const destination = result.destination;
    if (source.droppableId !== destination.droppableId) {
      return; // block cross list dropping
    }

    // did not move anywhere - can bail early
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    const sourceProj = projects[source.index];
    projects.splice(source.index, 1);
    projects.splice(destination.index, 0, sourceProj);

    // this order is not persisted between reloads yet
  };
  const isAdmin = user.role === USER.ROLE_SYS_ADMIN;
  const isConsultant = user.role === USER.ROLE_CONSULTANT;

  const getIcon = item => {
    return item.alert === null ? (
      <DateRange style={{ color: '#fff' }} className={classes.dueIcon} />
    ) : item.alert === 'overdue' ? (
      <DateRange style={{ color: red[500] }} className={classes.dueIcon} />
    ) : item.alert === 'today' || item.alert === 'soon' ? (
      <DateRange style={{ color: yellow[800] }} className={classes.dueIcon} />
    ) : (
      // item.alert === 'missing'
      <DateRange style={{ color: grey[500] }} className={classes.dueIcon} />
    );
  };

  const alertTypeVisible = alertType => {
    switch (alertType) {
      case 'overdue':
        return viewState.usibilityReport.overdue;
      case 'today':
        return viewState.usibilityReport.today;
      case 'soon':
        return viewState.usibilityReport.soon;
      case 'missing':
        return viewState.usibilityReport.missing;
      default:
        return null;
    }
  };

  const showLimitReachedDialog = type => {
    setOverLimit(type);
    // alert(`reached your limit: ${type}`)
  };

  const generateProjectRows = usibilityReport => {
    const projectRows = []; // flatten data into rows
    // falseProjectData.forEach(proj => {
    const alertCounts = {
      ['overdue']: 0,
      ['today']: 0,
      ['soon']: 0,
      ['missing']: 0,
    };
    if (usibilityReport) {
      // report state is loaded
      const projsCopy = usibilityReport.slice().reverse();
      projsCopy.forEach(proj => {
        let numVisibleDels = 0;
        const delsCopy = proj.deliverables.slice().reverse();
        delsCopy.forEach(del => {
          let numVisibleWPs = 0;
          const wpsCopy = del.workPackages.slice().reverse();
          wpsCopy.forEach(wp => {
            const wpAlertVisible = alertTypeVisible(wp.alert);
            const wpCountItem = !viewState.usibilityReport.justMe || wp.assignedTo === user.email;
            const wpShowVisibleAlerts = wpAlertVisible && wpCountItem;

            if (wpCountItem) {
              alertCounts[wp.alert]++;
            }
            // if (numVisibleTasks > 0 || wpShowVisibleAlerts) {
            if (wpShowVisibleAlerts) {
              numVisibleWPs++;
              projectRows.unshift({
                text: `${wp.name}`,
                dueText: wp.dueText ? wp.dueText : '',
                icon: getIcon(wp),
                level: 1,
                link: wp.link,
                priority: wp.priority,
                assignedTo: viewState.usibilityReport.justMe ? null : wp.assignedTo,
                justText: !wpShowVisibleAlerts,
                // !wpAlertVisible ||
                // (viewState.usibilityReport.justMe && wp.assignedTo !== user.email),
                showAvatar: !viewState.usibilityReport.justMe,
              });
            }
          });
          const delAlertVisible = alertTypeVisible(del.alert);
          const delCountItem = !viewState.usibilityReport.justMe || del.assignedTo === user.email;

          const delShowVisibleAlerts = delAlertVisible && delCountItem;
          if (numVisibleWPs > 0 || delShowVisibleAlerts) {
            numVisibleDels++;
          }
        });
        if (numVisibleDels > 0) {
          projectRows.unshift({
            text: `${proj.name}`,
            dueText: proj.dueText ? proj.dueText : '',
            icon: getIcon(proj),
            level: 0,
            link: proj.link,
            uuid: proj.uuid,
            justText: true,
            assignedTo: viewState.usibilityReport.justMe ? null : proj.projectManager,
            showAvatar: false,
            numWeeksInSprint: proj.numWeeksInSprint ?? 2,
          });
        }
      });
    }
    return { projectRows, alertCounts };
  };

  const { projectRows, alertCounts } = generateProjectRows(usibilityReport);

  const makePDF = () => {
    const { projectRows, alertCounts } = generateProjectRows(usibilityReport);
    // delete projectRows[0]; // remove project row
    const tableBody = [['Work Component', '!', 'Assigned', 'Alert']];
    projectRows.forEach(row => {
      const textLen = 60 - row.level * 3;
      const indent = row.level * 10;
      const bottom = [0, 0, 2, 4];
      const top = [5, 5, 4, 0];
      const assignedTo = row.assignedTo ?? '';
      const assignedToBits = assignedTo.split('@');
      const rowPriorityStr = row.priority ? '!' : '';
      tableBody.push([
        {
          text: `${row.text.substring(0, textLen)}`,
          // text: `${row.level === 3 ? '-  ' : ''}${row.text.substring(0, textLen)}`,
          marginLeft: indent,
          marginTop: top[row.level],
          marginBottom: bottom[row.level],
        },
        {
          text: rowPriorityStr,
          // text: row.level === 3 ? rowPriorityStr : '',
          marginTop: top[row.level],
          marginBottom: bottom[row.level],
        },
        {
          // text: row.level === 3 ? assignedToBits[0].substring(0, 11) : '',
          text: assignedToBits[0].substring(0, 11),
          marginTop: top[row.level],
          marginBottom: bottom[row.level],
        },
        {
          // text: row.level === 3 ? row.dueText : '',
          text: row.dueText,
          marginTop: top[row.level],
          marginBottom: bottom[row.level],
        },
      ]);
    });
    const creationDate = moment().format('GGGG-MM-DD');
    const docDefinition = {
      // a string or { width: number, height: number }
      pageSize: 'A4',
      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: 'landscape',
      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [40, 60, 40, 60],
      header: {
        columns: [
          { text: `Needs Attention for all Projects`, alignment: 'left', marginTop: 20, marginLeft: 40 },
          { text: `${creationDate}`, alignment: 'right', marginTop: 20, marginRight: 60 },
        ],
      },
      footer: (currentPage, pageCount) => {
        return {
          text: `Page ${currentPage.toString()} of ${pageCount}`,
          alignment: 'center',
          marginBottom: -20,
        };
      },

      content: [
        {
          layout: 'headerLineOnly', // - for other options see https://pdfmake.github.io/docs/0.1/document-definition-object/tables/
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: [380, 20, 83, 120],
            body: tableBody,
          },
        },
      ],
    };
    // create the window before the callback
    var win = window.open('', '_blank');
    pdfMake.createPdf(docDefinition).open({}, win);
  };
  const numStrOverdue = alertCounts['overdue'] === 0 ? '' : `(${alertCounts['overdue']})`;
  const numStrDueToday = alertCounts['today'] === 0 ? '' : `(${alertCounts['today']})`;
  const numStrSoon = alertCounts['soon'] === 0 ? '' : `(${alertCounts['soon']})`;
  const numStrMissingDate = alertCounts['missing'] === 0 ? '' : `(${alertCounts['missing']})`;
  const textOverdue = `Overdue ${numStrOverdue}`;
  const textDueToday = `Due Today ${numStrDueToday}`;
  const textSoon = `Due Soon ${numStrSoon}`;
  const textMissingDate = `Date Missing ${numStrMissingDate}`;
  const totalItems =
    alertCounts['overdue'] + alertCounts['today'] + alertCounts['soon'] + alertCounts['missing'];

  let ownedProjects = 0;
  let sharedProjects = 0;
  const isFreePlan = !user.plan || user.plan.name === USER.FREE_PLAN_NAME;
  projects.forEach(project => {
    if (project.creator === user.email) {
      if (!isFreePlan || ownedProjects < USER.FREE_PLAN_OWNED_PROJ_LIMIT) {
        delete project.blockAccess;
      } else {
        project.blockAccess = 'owned';
      }
      ownedProjects++;
    } else {
      if (!isFreePlan || sharedProjects < USER.FREE_PLAN_SHARED_PROJ_LIMIT) {
        delete project.blockAccess;
      } else {
        project.blockAccess = 'shared';
      }
      sharedProjects++;
    }
  });
  // const canAddProjects = !isFreePlan || ownedProjects < USER.FREE_PLAN_OWNED_PROJ_LIMIT;
  const canAddProjects = true;

  const filterButtonTitles = ['Overdue', 'Today', 'Current', 'Schedule !'];
  const filterButtons = ['overdue', 'today', 'soon', 'missing'].reduce((acc, key, index) => {
    if (alertCounts[key]) {
      const button = (
        <div
          className={classes.statusFilterButton}
          style={{
            backgroundColor: viewState.usibilityReport[key]
              ? theme.palette.secondary.main
              : theme.palette.secondary.light,
            marginLeft: '10px',
          }}
          onClick={e => {
            const newUsibilityReport = { ...viewState.usibilityReport };
            newUsibilityReport[key] = !viewState.usibilityReport[key];
            dispatchUpdateViewState({ ...viewState, usibilityReport: newUsibilityReport });
          }}
        >
          <Typography
            className={classes.statusFilterButtonText}
            style={{ color: viewState.usibilityReport[key] ? '#FFF' : '#AAA' }}
          >
            {filterButtonTitles[index]}
          </Typography>
        </div>
      );
      acc.push(button);
    }
    return acc;
  }, []);

  const avatarBkgdColor = userPrefs.collegues[user.email]
    ? viewState.usibilityReport.justMe
      ? userPrefs.collegues[user.email].color
      : userPrefs.collegues[user.email].color?.substring(0, 7) + '50'
    : 'lightGrey';

  const getHeight = element => {
    if (element && !assignmentListHeight) {
      setAssignmentListHeight(element.clientHeight);
    }
  };
  const xProjectAssignments = utils.getAssignments(
    projects,
    { [user.email]: true },
    assignments?.assignedTaskIds ?? [],
  );

  return (
    <div className={classes.rootContainer}>
      <ContentWrapper title="Dashboard">
        {/* <ContentWrapper title="Dashboard" scrollY> */}
        <div className={classes.parentContainer}>
          <div className={classes.leftSide}>
            <DragDropContext onDragEnd={onDragEnd}>
              <div
                className={classes.projectsContainer}
                ref={el => {
                  if (el) {
                    el.scrollTop = viewState.scrollTop.dashboardProjects
                      ? viewState.scrollTop.dashboardProjects
                      : 0;
                  }
                }}
                onScroll={e => {
                  if (e.currentTarget.scrollTop !== viewState.scrollTop.dashboardProjects) {
                    if (scrollTimer) {
                      clearTimeout(scrollTimer);
                    }
                    const scrollTopProj = e.currentTarget.scrollTop;
                    scrollTimer = setTimeout(e => {
                      // debounce scoll values
                      viewState.scrollTop.dashboardProjects = scrollTopProj;
                      // console.log(
                      //   `scroll ${viewState.scrollTop.dashboardProjects}`,
                      // );
                      updateViewState(viewState);
                      scrollTimer = undefined;
                    }, 50);
                  }
                }}
              >
                <div className={classes.projectRow}>
                  <Heading heading="Projects" size={'2rem'} indent={'40px'} />
                  <AddButton
                    handleClick={() => {
                      if (canAddProjects) {
                        viewState = Object.assign(viewState, {
                          curWindow: NAV.CREATE_NEW_PROJECT,
                        });
                        dispatchUpdateViewState(viewState);
                      } else {
                        showLimitReachedDialog('addNew');
                      }
                    }}
                    size="medium"
                    color={'#FFF'}
                    bgdColor={theme.palette.primary.addButton}
                    hoverColor={theme.palette.secondary.addButton + 'D0'}
                    borderColor={theme.palette.primary.addButton}
                    tooltip="add a new project"
                    containerClassName={classes.addButtonContainer}
                  />
                </div>
                <div className={classes.projectListContainer}>
                  <DroppableProjectCardList
                    listId={'Projects'}
                    projects={projects}
                    isWorkflow={false}
                    showLimitReachedDialog={showLimitReachedDialog}
                  />
                </div>
                {isAdmin && workflows[constants.CORE_SMART_TEMPLATES_ID] && (
                  <Fragment>
                    <Heading heading="Core Smart Workflows" size={'2rem'} indent={'40px'} />
                    <div className={classes.projectListContainer}>
                      <DroppableWorkflowCardList
                        listId={'Workflows'}
                        workflows={workflows}
                        templateSetId={constants.CORE_SMART_TEMPLATES_ID}
                        saveWorkflows={saveWorkflows}
                      />
                    </div>
                  </Fragment>
                )}
                {isAdmin && workflows[constants.PUBLIC_GROUP_ID] && (
                  <Fragment>
                    <Heading heading="Public Workflows" size={'2rem'} indent={'40px'} />
                    <div className={classes.projectListContainer}>
                      <DroppableWorkflowCardList
                        listId={'Workflows'}
                        workflows={workflows}
                        templateSetId={constants.PUBLIC_GROUP_ID}
                        saveWorkflows={saveWorkflows}
                      />
                    </div>
                  </Fragment>
                )}
              </div>
            </DragDropContext>
          </div>
          <div className={classes.rightSide} ref={getHeight}>
            <Fragment>
              <div
                style={{
                  paddingBottom: '5px',
                }}
              >
                <div className={classes.reportButtons}>
                  <Button
                    size={'large'}
                    onClick={() => {
                      if (reportTab !== 'Assignments') {
                        setReportTab('Assignments');
                      }
                    }}
                    disabled={reportTab === 'Assignments'}
                    className={reportTab === 'Assignments' ? classes.enabledReport : classes.disabledReport}
                  >
                    <Typography
                      variant={'h6'}
                      className={classes.reportTitle}
                      style={{ color: reportTab === 'Assignments' ? '#444' : '#AAA' }}
                    >
                      {'My Task Assignments'}
                    </Typography>{' '}
                  </Button>
                  <Button
                    size={'large'}
                    onClick={() => {
                      if (reportTab !== 'NeedsAttention') {
                        setReportTab('NeedsAttention');
                      }
                    }}
                    disabled={reportTab === 'NeedsAttention'}
                    className={
                      reportTab === 'NeedsAttention' ? classes.enabledReport : classes.disabledReport
                    }
                  >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography
                        variant={'h6'}
                        className={classes.reportTitle}
                        style={{
                          marginLeft: reportTab === 'NeedsAttention' ? '40px' : '0px',
                          color: reportTab === 'NeedsAttention' ? '#444' : '#AAA',
                        }}
                      >
                        {'Needs Attention'}
                      </Typography>{' '}
                      {reportTab === 'NeedsAttention' && (
                        <Tooltip title={'save as PDF'}>
                          <div
                            size={'small'}
                            onClick={() => {
                              makePDF();
                            }}
                            disabled={projectRows.length === 0}
                            className={classes.pdfButton}
                          >
                            <PictureAsPdf style={{ height: '50px', width: '35px' }} />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </Button>
                </div>
              </div>
              {reportTab === 'Assignments' ? (
                <Fragment>
                  <div className={classes.naTitle}>
                    <Typography variant={'body1'} className={classes.assignSubTitle}>
                      {'in the current or earlier work periods'}
                    </Typography>
                  </div>
                  {!xProjectAssignments.assignmentInfo ? (
                    <div className={classes.loadingIndicator}>
                      <Typography
                        variant={'h6'}
                        className={classes.attentionMessage}
                        style={{ fontWeight: 500 }}
                      >
                        {'  refreshing...'}
                      </Typography>
                    </div>
                  ) : xProjectAssignments.assignmentInfo.length === 0 ? (
                    <div className={classes.loadingIndicator}>
                      <Typography
                        variant={'h6'}
                        className={classes.attentionMessage}
                        style={{ fontWeight: 600 }}
                      >
                        {'No tasks assigned.'}
                      </Typography>
                    </div>
                  ) : (
                    <div
                      ref={ref => (listContainerRef.current = ref)} //
                      className={classes.assignmentListContainer}
                    >
                      <DroppableAssignmentsList
                        assignments={xProjectAssignments}
                        listId={'Workflows'}
                        workflows={workflows}
                        templateSetId={constants.PUBLIC_GROUP_ID}
                        saveWorkflows={saveWorkflows}
                        saveAssignments={saveAssignments}
                        showAssignment={false}
                        assignmentListHeight={
                          listContainerRef.current ? listContainerRef.current.clientHeight : undefined
                        }
                      />
                    </div>
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  <div className={classes.buttonRow}>
                    <Tooltip
                      title={
                        viewState.usibilityReport.justMe
                          ? 'click to show all work'
                          : 'click to show just your work'
                      }
                    >
                      <div
                        className={classes.assignedToAvatar}
                        style={{ backgroundColor: avatarBkgdColor }}
                        onClick={e => {
                          Object.assign(viewState.usibilityReport, {
                            justMe: !viewState.usibilityReport.justMe,
                          });
                          dispatchUpdateViewState({ ...viewState });
                        }}
                      >
                        <Typography className={classes.assignedToAvatarText}>
                          {user.email.substring(0, 1).toUpperCase()}
                        </Typography>
                      </div>
                    </Tooltip>
                    <div style={{ flex: 1 }} />
                    <div className={classes.statusButtonRow}>{filterButtons}</div>
                  </div>
                  {!usibilityReport ? (
                    <div className={classes.loadingIndicator}>
                      <Typography
                        variant={'h6'}
                        className={classes.attentionMessage}
                        style={{ fontWeight: 500 }}
                      >
                        {'  refreshing...'}
                      </Typography>
                    </div>
                  ) : projectRows.length === 0 ? (
                    <div className={classes.loadingIndicator}>
                      <Typography
                        variant={'h6'}
                        className={classes.attentionMessage}
                        style={{ fontWeight: 600 }}
                      >
                        {totalItems === 0
                          ? 'All up to date.  No items flagged.'
                          : 'Select a filter button in the upper right to display items.'}
                      </Typography>
                    </div>
                  ) : (
                    <div
                      ref={el => {
                        if (el) {
                          el.scrollTop = viewState.scrollTop.dashboardNeedsAttention
                            ? viewState.scrollTop.dashboardNeedsAttention
                            : 0;
                        }
                      }}
                      className={classes.reportlist}
                      onScroll={e => {
                        if (e.currentTarget.scrollTop !== viewState.scrollTop.dashboardNeedsAttention) {
                          if (scrollTimer) {
                            clearTimeout(scrollTimer);
                          }
                          const scrollTopNA = e.currentTarget.scrollTop;
                          scrollTimer = setTimeout(e => {
                            // debounce scoll values
                            viewState.scrollTop.dashboardNeedsAttention = scrollTopNA;
                            // console.log(
                            //   `scroll ${viewState.scrollTop.dashboardNeedsAttention}`,
                            // );
                            updateViewState(viewState);
                            scrollTimer = undefined;
                          }, 250);
                        }
                      }}
                    >
                      {projectRows.map((r, index) => {
                        const lMargin = r.level * 20;
                        const fWeight = r.level === 0 ? 600 : 500;
                        const mTop = r.level === 0 ? 15 : 2;
                        const avatarId = r.assignedTo ? r.assignedTo.substring(0, 1).toUpperCase() : null;
                        let collegueColor;
                        if (r.assignedTo) {
                          if (userPrefs.collegues[r.assignedTo]) {
                            collegueColor = userPrefs.collegues[r.assignedTo].color;
                          } else {
                            collegueColor = utils.randColor();
                          }
                        }
                        return (
                          <div className={classes.urProjectRow} style={{ marginTop: mTop }} key={index}>
                            <Typography
                              onClick={e => {
                                // Object.assign(viewState, {
                                //   currentProjectType: NAV.TYPE_PROJECT,
                                //   currentProjectIndex: r.link.projIndex,
                                //   curWindow: NAV.SPRINTVIEW,
                                //   detailsTabIndex: 0,
                                //   currentDeliverable: 0,
                                //   currentWorkPackage: 0,
                                //   currentTask: 0,
                                //   wbsStack: [r.link.stack[0]],
                                // });
                                const topStack = r.link.stack[r.link.stack.length - 1];

                                Object.assign(viewState, {
                                  currentProjectType: NAV.TYPE_PROJECT,
                                  currentProjectIndex: r.link.projIndex,
                                  curProjectUUID: r.link.projUUID,
                                  lastWindow: {
                                    ...viewState.lastWindow,
                                    [r.link.projUUID]: NAV.SPRINTVIEW,
                                  },
                                  curWindow: topStack.screen,
                                  detailsTabIndex: 0,
                                  currentDeliverable: topStack.item.d > 0 ? topStack.item.d : 0,
                                  currentWorkPackage: topStack.item.w > 0 ? topStack.item.w : 0,
                                  currentTask: topStack.item.t > 0 ? topStack.item.t : 0,
                                  wbsStack: r.link.stack,
                                });
                                dispatchUpdateViewState(viewState);
                              }}
                              variant={'h6'}
                              className={classes.alertComponentText}
                              style={{
                                fontWeight: fWeight,
                                marginLeft: lMargin,
                              }}
                            >
                              {r.text}
                            </Typography>
                            {r.priority && <PriorityIcon />}

                            {!r.showAvatar ? null : avatarId ? (
                              <Tooltip title={r.assignedTo}>
                                <div
                                  className={classes.assignedToAvatar}
                                  style={{ backgroundColor: collegueColor }}
                                >
                                  <Typography className={classes.assignedToAvatarText}>{avatarId}</Typography>
                                </div>
                              </Tooltip>
                            ) : (
                              <div
                                className={classes.assignedToAvatar}
                                style={{ backgroundColor: '#A0A0A020' }}
                              >
                                <Person className={classes.notAssignedTo} />
                              </div>
                            )}
                            {r.level !== 0 && !r.justText ? (
                              <div className={classes.alertContainer}>
                                {r.icon}
                                <Typography variant={'h6'} className={classes.dueText}>
                                  {r.dueText}
                                </Typography>
                              </div>
                            ) : (
                              <div className={classes.alertContainer}>
                                <Typography variant={'h6'} className={classes.blankDueText}>
                                  {' '}
                                </Typography>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </Fragment>
              )}
            </Fragment>
          </div>
        </div>
        {overLimit && (
          <OverLimitDialog
            viewState={viewState}
            limitType={overLimit}
            showLimitReachedDialog={showLimitReachedDialog}
            dispatchUpdateViewState={dispatchUpdateViewState}
          />
        )}
        {onboardingDialog && (
          <OnboardingDialog
            title={'This is your Dashboard.'}
            text={[
              'Tap a project to access OVERVIEW information.',
              'Tap a project twice to open it.',
              'Tap the + button to add a project.',
              'Monitor the Needs Attention report often.',
            ]}
            links={null}
            // links={[{ text: 'here', link: 'https://www.youtube.com/watch?v=iri7sPdQF08' }]}
            onboardingKey={USER.ONBOARDING_DASHBOARD_DIALOG}
            closeDialog={setOnboardingDialog}
            textOffset={'60px'}
          />
        )}

        {/* {planhas(consultantPackage) && group === null && ( */}
        {(isAdmin || isConsultant) && viewState.showGroupDialog && (
          <GroupNameDialog
            viewState={viewState}
            group={group}
            dispatchUpdateViewState={dispatchUpdateViewState}
            dispatchSetGroup={dispatchSetGroup}
            user={user}
            firebase={firebase}
          />
        )}
      </ContentWrapper>
    </div>
  );
};

export default connect(
  state => {
    return {
      projects: state.projects,
      workflows: state.workflows,
      viewState: state.viewState,
      firebase: state.firebase,
      usibilityReport: state.usibilityReport,
      assignments: state.assignments,
      userPrefs: state.userPrefs,
      group: state.group,
    };
  },
  {
    handleMenu,
    saveCurrentProject,
    saveWorkflows,
    dispatchUpdateViewState,
    dispatchSetGroup,
    dispatchUpdateUsibilityReport,
    updateViewState,
    saveAssignments,
  },
)(Dashboard);
