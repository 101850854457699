/* eslint-disable */
import moment from 'moment';
import { red, yellow, grey } from '@material-ui/core/colors';
import { detect } from 'detect-browser';

import * as constants from '../../constants/app.js';
import * as ACTION from './actionTypes';
import * as USER from '../../constants/user';
import * as ANALYTICS from '../../constants/analytics';
import * as NAV from '../../constants/navigation';

import * as schema from '../../data/projectSchema.js';
import * as utils from '../../utils/generalUtilities.js';
import * as STATUS from '../../constants/status';

import {
  getUserPreferences,
  addNewUserPreferencesDoc,
  updateUserPreferencesDoc,
  updatePreferenceTemplates,
} from './firebase/userPreferences';
import { getUserGroupIDs, getUsersGroups } from './firebase/userGroups';
import { saveCurrentProject, setGroup, batchSaveProjects, batchSaveWorkflows } from './userInterface';
import { fetchPlans } from './firebase/userPlans';
import { getUsersAssignments } from './firebase/userAssignments.js';
import { getUserProjects, addNewProject, orderProjects } from './firebase/userProjects';
import { getUserTemplates, filterTemplatesByGroup, createCoreSmartTemplates } from './firebase/userTemplates';

import { saveSession, analyticsSystemEvent } from './analytics';

// export function getOnboardingDefaults() {
//   return {
//     type: ACTION.RESET_ONBOARDING,
//   };
// }
export const getOnboardingDefaults = () => {
  const cases = {};
  cases[USER.ONBOARDING_WELCOME_DIALOG] = true;
  cases[USER.ONBOARDING_DASHBOARD_DIALOG] = true;
  cases[USER.ONBOARDING_PROJ_PLAN_DIALOG] = true;
  cases[USER.ONBOARDING_WBS_DIALOG] = true;
  cases[USER.ONBOARDING_WBS_ZOOMED_DIALOG] = true;
  cases[USER.ONBOARDING_WORK_SCHEDULE_DIALOG] = true;
  cases[USER.ONBOARDING_SPRINTVIEW_DIALOG] = true;
  cases[USER.ONBOARDING_DELIVERABLE_DIALOG] = true;
  cases[USER.ONBOARDING_WORKPACKAGE_DIALOG] = true;
  cases[USER.ONBOARDING_TASK_DIALOG] = true;
  // cases[USER.ONBOARDING_EXAMPLE_PROJECT_DIALOG] = true;

  return cases;
};

export const getInitialUserPrefs = () => {
  return {
    collegues: {},
    onboarding: getOnboardingDefaults(),
    templateList: [],
  };
};

export function dispatchSignOutUser() {
  return (dispatch, getState) => {
    signOutUser(dispatch, getState());
  };
}

export function signOutUser(dispatch, store) {
  // if session is active, force save (used during logout and other situations)

  const { session, firebase } = store;
  const now = new Date().getTime();
  console.log(`Request to sign out user...`);

  if (
    session.startTS &&
    session.saveToBackend &&
    now - session.lastSessionUpdateTS < ANALYTICS.SESSION_EXPIRY_TIME_MS
  ) {
    console.log(`unsaved session data detected, uploading data to cloud...`);
    saveSession(store, true) // if active session, save it and logout afterward
      .then(response => {
        console.log(`Signing out user after saveSession: ${JSON.stringify(response)}`);
        if (response) dispatch({ type: ACTION.SESSION_SAVED });
        console.log(`Signing out user after saveSession2: ${JSON.stringify(response)}`);
        try {
          firebase.auth.signOut();
        } catch (e) {
          console.log(`caught signout error: ${e}`);
        }
      })
      .catch(error => {
        console.log(
          `Error saving session before signing out user, skipping session upload: ${JSON.stringify(
            response,
          )}`,
        );
        console.log(`Signing out user without saveSession`);
        try {
          firebase.auth.signOut();
        } catch (e) {
          console.log(`caught signout error: ${e}`);
        }
      });
  } else {
    console.log(`Signing out user without saveSession`);
    try {
      firebase.auth.signOut();
    } catch (e) {
      console.log(`caught signout error: ${e}`);
    }
  }
}

export function dispatchUpdateUsibilityReport() {
  return (dispatch, getState) => {
    const { projects } = getState();
    const projData = utils.getProjectAlerts(projects);
    dispatch(updateUsibilityReport(projData));
  };
}

export function onboardingUpdate(onboarding) {
  return (dispatch, getState) => {
    dispatch({
      type: ACTION.SET_ONBOARDING,
      onboarding: onboarding,
    });
  };
}

export const updateUsibilityReport = projData => {
  return {
    type: ACTION.SET_USIBILITY_REPORT_DATA,
    projData: projData,
  };
};

export function clearUserInfo(error = null) {
  return (dispatch, getState) => {
    let { user } = getState();

    dispatch({
      type: ACTION.CLEAR_USER_INFO,
      error: error, // TODO hook up error feedback
      prevUserUID: user.uid,
    });
  };
}

export function touchUserEmail(userEmail) {
  // update user email while user info loads, forces signin screen closure
  return (dispatch, getState) => {
    dispatch({
      type: ACTION.TOUCH_USER_EMAIL,
      email: userEmail,
    });
  };
}

export function genCommunity(userEmail, projects) {
  let community = [userEmail];
  projects.forEach(project => {
    if (project.creator === userEmail) {
      community = community.concat(project.sharedWith);
    }
  });
  community = community.filter((v, i, a) => a.indexOf(v) === i); // get unique values
  community.sort((a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  });
  return community;
}

export function togglePinVisibility() {
  return (dispatch, getState) => {
    dispatch({
      type: ACTION.TOGGLE_PIN_VISIBILITY,
    });
  };
}

export function toggleDetailPinVisibility() {
  return (dispatch, getState) => {
    dispatch({
      type: ACTION.TOGGLE_DETAIL_PIN_VISIBILITY,
    });
  };
}

export function newAccountRequested() {
  return {
    type: ACTION.NEW_ACCOUNT_REQUESTED,
  };
}

export function stickyNoteTilt(itemUUID) {
  // map item.uuid hash to tilt value - deterministic - same tilt no matter what
  const cleanedUUID = `0x${itemUUID.replace(/-/g, '').substring(0, 10)}`;
  const seed = Number(cleanedUUID);
  const tilt = ((seed % 21) / 10 - 1) / 5.0;
  // console.log(tilt);
  return tilt;
}
export function loadUserInfo(userToken) {
  return (dispatch, getState) => {
    let { firebase, user, projects, app, workflows, viewState } = getState();

    if (!userToken) return;

    // const isNewUser = app.newAccountRequested; // hold as this will be reset by setUserInfo

    const userDataFromFB = {};
    // first, get user's plans, groups and preferences (in parallel)
    const getPlansPromise = fetchPlans(firebase, userToken.uid);
    const getGroupsPromise = getUsersGroups(firebase, userToken.email);
    const getAssignedTaskIdsPromise = getUsersAssignments(firebase, userToken.uid);
    Promise.all([getPlansPromise, getGroupsPromise, getAssignedTaskIdsPromise])
      .then(([planData, groupsInfo, assignements]) => {
        userDataFromFB['planData'] = planData;
        userDataFromFB['assignedTaskIds'] = assignements;
        userDataFromFB['userRole'] =
          planData.userPlan.name === 'Admin Plan'
            ? USER.ROLE_SYS_ADMIN
            : planData.userPlan.name.endsWith('+ Plan')
            ? USER.ROLE_CONSULTANT
            : USER.ROLE_GENERAL_USER;
        console.log(`  loadUserInfo(${userToken.displayName})  role: ${userDataFromFB['userRole']}`);

        userDataFromFB['adminGroup'] = groupsInfo.adminGroup; // has a value if the user is the administrator of a group
        userDataFromFB['userGroups'] = groupsInfo.userGroups; // all private groups that the user belongs to

        let userGroupIDs = getUserGroupIDs(groupsInfo.userGroups, userDataFromFB['userRole']);
        console.log(
          `  set up project/template load promises - userGroupIDs = ${JSON.stringify(userGroupIDs)}`,
        );
        if (userGroupIDs.length > 10) {
          console.error(`  this user belongs to more than 10 groups - limiting groups to first 10`);
          alert(
            `You belong to more than 10 private workflow groups. We are limiting you to the first 10 to prevent system errors. As a result you may not have access to all the workflows shared with you.`,
          );
          userGroupIDs = userGroupIDs.slice(0, 10);
        }

        const getTemplatesPromise = getUserTemplates(firebase, 'workflowSetId', 'in', userGroupIDs); // limited to 10 userGroupIDs, otherwise error message - firebase limitation
        const getPreferencesPromise = getUserPreferences(firebase, userToken.uid);
        const getProjectsPromise = getUserProjects(firebase, 'creator', '==', userToken.email);
        const getSharedProjectsPromise = getUserProjects(
          firebase,
          'sharedWith',
          'array-contains',
          userToken.email,
        );

        console.log(`  executing project/template load promises`);

        return Promise.all([
          getTemplatesPromise,
          getProjectsPromise,
          getSharedProjectsPromise,
          getPreferencesPromise,
        ]);
      })
      // .then(([projectsFromServer, sharedProjectsFromServer]) => {
      .then(([templatesFromServer, projectsFromServer, sharedProjectsFromServer, preferences]) => {
        console.log(`  projects loaded, now processing them`);
        const projectsNeedingSchemaUpgrade = [];
        const workflowsNeedingSchemaUpgrade = [];

        userDataFromFB['templates'] = {};
        userDataFromFB['preferences'] = Object.assign({}, getInitialUserPrefs(), preferences); // user's preferences

        if (userDataFromFB['userRole'] !== USER.ROLE_GENERAL_USER) {
          let coreSmartTemplatesExist = false;
          templatesFromServer.forEach(template => {
            if (template.workflowSetId === constants.CORE_SMART_TEMPLATES_ID) coreSmartTemplatesExist = true;
          });
          if (!coreSmartTemplatesExist) {
            const newCoreTemplates = createCoreSmartTemplates(userToken.email);
            const fbBatch = firebase.db.batch();
            newCoreTemplates.forEach(template => {
              const docRef = firebase.db.collection('workflows').doc(); //automatically generate unique id
              template.id = docRef.id;
              fbBatch.set(docRef, template);
            });
            fbBatch
              .commit()
              .then(result => {
                // dispatch(setWorkflows(templates));  // this will be done in App.js componentDidUpdate()
              }) // workflows store update now done in snapshot callback - see App.js componentDidUpdate
              .catch(function(error) {
                console.log(`Error adding core smart templates to backend: ${error}`);
              });

            templatesFromServer = templatesFromServer.concat(newCoreTemplates);
          }
        }
        let { filteredTemplates, existingTemplateIds, existingSetIds } = filterTemplatesByGroup(
          userDataFromFB['userGroups'],
          templatesFromServer,
          userToken.email,
        );
        userDataFromFB['existingTemplateIds'] = existingTemplateIds;
        userDataFromFB['templates'] = filteredTemplates;

        if (userDataFromFB['templates'].length === 0)
          throw new Error(`No templates available for user - ${templatesFromServer.length}`);
        // sort each template set by order
        existingSetIds.forEach(setId => {
          userDataFromFB['templates'][setId].sort((p1, p2) =>
            p1.library.order < p2.library.order ? -1 : p1.library.order === p2.library.order ? 0 : 1,
          );
        });

        templatesFromServer.forEach(workflow => {
          if (workflow && !workflow.deletedDate && workflow.version !== schema.SCHEMA_VERSION) {
            workflowsNeedingSchemaUpgrade.push(schema.migrateTemplateSchema(workflow));
          }
        });

        const allProjects = [...projectsFromServer, ...sharedProjectsFromServer];
        userDataFromFB['projects'] = [];
        const projectIds = {};
        allProjects.forEach(project => {
          if (project) {
            if (!projectIds[project.id]) {
              projectIds[project.id] = true;
              if (project && !project.deletedDate && project.version !== schema.SCHEMA_VERSION) {
                const updatedProject = schema.migrateProjectSchema(project);
                if (updatedProject) {
                  projectsNeedingSchemaUpgrade.push(updatedProject);
                  userDataFromFB['projects'].push(updatedProject);
                }
              } else {
                userDataFromFB['projects'].push(project);
              }
            }
          }
        });
        console.log(
          `myProjects(${projectsFromServer.length}), sharedProjects(${sharedProjectsFromServer.length})`,
        );

        if (projectsNeedingSchemaUpgrade.length > 0) {
          setTimeout(() => {
            console.log(`saving ${projectsNeedingSchemaUpgrade.length} projects with updated schemas`);
            batchSaveProjects(projectsNeedingSchemaUpgrade, getState);
          }, 250);
        }
        if (workflowsNeedingSchemaUpgrade.length > 0) {
          setTimeout(() => {
            console.log(`saving ${workflowsNeedingSchemaUpgrade.length} workflows with updated schemas`);
            batchSaveWorkflows(workflowsNeedingSchemaUpgrade, getState);
          }, 300);
        }

        // let community = [userToken.email];
        // projectsFromServer.forEach(project => {
        //   community = community.concat(project.sharedWith);
        // });
        // userDataFromFB['community'] = community.filter((v, i, a) => a.indexOf(v) === i); // get unique values
        // userDataFromFB['community'].sort((a, b) => {
        //   if (a < b) return -1;
        //   if (a > b) return 1;
        //   return 0;
        // });

        // from the groups that the user belongs to, then load existing templates for those groups
        // at same time load projects for the user
        let setPreferencesPromise;
        if (userDataFromFB['preferences']) {
          // if prefs exist in cloud, check that all references are still valid, otherwise update
          setPreferencesPromise = Promise.resolve();

          // ensure the avatar color for the user's email exist
          if (!userDataFromFB['preferences'].collegues[userToken.email]?.color) {
            userDataFromFB['preferences'].collegues[userToken.email] = { color: utils.randColor() };
          }

          let curTemplateList = userDataFromFB['preferences'].templateList.slice();
          const testList = [];
          curTemplateList.forEach(templateId => {
            if (userDataFromFB['existingTemplateIds'][templateId]) testList.push(templateId);
          });
          if (testList.length != userDataFromFB['templates'].length) {
            // first time (new user) or referenced templates have changed/been removed
            const defaultPrefs = updatePreferenceTemplates(
              userDataFromFB['preferences'],
              userDataFromFB['templates'],
            );
            userDataFromFB['preferences'].templateList = [
              // filters out duplicates - use Set() in next line filters out duplicates
              ...new Set(testList.concat(defaultPrefs.templateList)),
            ];
            userDataFromFB['preferences'].templateList.slice(0, 2);
            setPreferencesPromise = updateUserPreferencesDoc(
              firebase,
              userToken.uid,
              userDataFromFB['preferences'],
            );
          }
        }

        // check for first time user - will now work, even if user has shared projects
        let newProjectPromise = Promise.resolve();

        if (projectsFromServer.length === 0) {
          // if new User create one
          const now = moment()
            .local()
            .format(); // in local time
          const recordId = `${ANALYTICS.SIGNUP_RECORD}::${userToken.email}::${now}`;
          const browser = detect(); // get browser info

          analyticsSystemEvent(getState(), recordId, ANALYTICS.SIGNUP_RECORD, {
            user: {
              uid: userToken.uid,
              email: userToken.email,
              role: userDataFromFB['userRole'],
            },
            browser: browser.name,
            os: browser.os,
          });

          const starterTemplate = utils.getStarterProjectTemplate(userDataFromFB['templates']);
          let newProject = utils.createNewProject(
            // constants.FIRST_PROJECT_NAME,
            starterTemplate.name,
            userToken.email,
            starterTemplate,
          );
          if (
            newProject.goalsAndObjectives.blocks &&
            newProject.goalsAndObjectives.blocks[0] &&
            newProject.goalsAndObjectives.blocks[0].text === '-- Example project'
          ) {
            // userDataFromFB['preferences'].onboarding[USER.ONBOARDING_EXAMPLE_PROJECT_DIALOG] = true;
            newProject.goalsAndObjectives.blocks.shift();
            const firstDel = newProject.deliverables[0];
            const firstWP = firstDel.workPackages[0];
            const startDayOffset = [-3, 0, 0];
            const endDayOffset = [null, null, +2];
            [0, 1, 2].forEach((num, ind) => {
              if (firstWP.tasks[num]) {
                const newMoment = moment();
                // console.log(`Example project update blocks: ${JSON.stringify(newMoment)}`);
                firstWP.tasks[num].expectedStartDate = newMoment
                  .add(startDayOffset[ind], 'day')
                  .local()
                  .format('YYYY-MM-DD'); // in local time
                if (endDayOffset[ind]) {
                  // console.log(`Example project 2`);
                  firstWP.tasks[num].expectedEndDate = newMoment
                    .add(endDayOffset[ind], 'day')
                    .local()
                    .format('YYYY-MM-DD'); // in local time
                  firstWP.tasks[num].status = STATUS.IN_PROGRESS;
                  // firstWP.status = STATUS.IN_PROGRESS;
                }
                firstWP.tasks[num].assignedTo = newProject.creator;
              }
            });
            utils.refreshStats(newProject);
          }
          newProjectPromise = addNewProject(firebase, newProject);
        }
        return Promise.all([newProjectPromise, setPreferencesPromise]);
      })
      .then(([newProject, setPresResponse]) => {
        let newViewState;
        if (newProject) {
          // new user projects
          userDataFromFB['projects'].push(newProject);
          newViewState = {
            ...viewState,
            curWindow: NAV.BOARD,
            lastWindow: { ...viewState.lastWindow, [newProject.uuid]: NAV.BOARD },
            wbsStack: [{ screen: NAV.BOARD, item: { d: -1, w: -1, t: -1 }, level: 'p' }],
          };
        }
        orderProjects(userDataFromFB['projects']);
        const defaultWF = utils.getDefaultTemplate(userDataFromFB['templates']);
        userDataFromFB['projects'].forEach(proj => {
          if (!proj.templateRefUUID) {
            proj.templateRefUUID = defaultWF.uuid;
          }
        });

        if (userDataFromFB['userRole'] === USER.ROLE_CONSULTANT && !userDataFromFB['adminGroup']) {
          // new consultant without group or smart templates
          setTimeout(() => {
            const newGroup = {
              id: '',
              name: `${user.email.trim().substring(0, 25)}`,
              members: [user.email.trim()],
              tags: '',
              isAdmin: true,
              info: {
                [user.email.trim()]: { isAdmin: true, tags: [] },
              },
            };
            // viewState = Object.assign(viewState, {
            //   prevWindow: null,
            //   curWindow: NAV.GROUPPAGE,
            //   showGroupDialog: false,
            // });
            setGroup(dispatch, getState, newGroup, 'name', firebase, viewState);
          }, 10);
        }

        if ((!app.prevUserUID || app.prevUserUID !== userToken.uid) && !newProject) {
          // switched users and not new user
          if (
            !viewState.currentProjectIndex ||
            viewState.currentProjectIndex < 0 ||
            viewState.currentProjectIndex >= userDataFromFB['projects'].length
          ) {
            viewState.currentProjectIndex = 0;
          }
          const curProjectUUID = userDataFromFB['projects'][viewState.currentProjectIndex ?? 0].uuid;
          const prevBoard = viewState.lastWindow[curProjectUUID] ?? NAV.BOARD;
          newViewState = {
            ...viewState,
            curProjectUUID: curProjectUUID,
            numWeeksInSprint: userDataFromFB['projects'][viewState.currentProjectIndex ?? 0].numWeeksInSprint,
            curWindow: prevBoard,
            // prevWindow: null,
            wbsStack: [{ screen: prevBoard, item: { d: -1, w: -1, t: -1 }, level: 'p' }],
            currentProjectType: NAV.TYPE_PROJECT,
            currentProjectIndex: 0,
            currentWorkflowIndex: 0,
            currentWorkflowSetId: constants.PUBLIC_GROUP_ID,
            currentDeliverable: 0,
            currentWorkPackage: 0,
            currentTask: 0,
            scrollTop: {},
            projectScheduleScrollInfo: { scrollTop: 0, scrollHeight: 100 },
            wbsStack: [{ screen: NAV.BOARD, item: { d: -1, w: -1, t: -1 }, level: 'p' }], // new nav item, makes currentDel/WP/Task above redundant .. todo remove those in future
            libStack: [
              {
                screen: NAV.LIBRARY_BOARD,
                item: { d: -1, w: -1, t: -1 },
                level: 'p',
              },
            ],
          };
        }

        console.log(`+++++++++++++++++++ running getAssignments from loadUserInfo +++++++++++++++`);
        dispatch({
          type: ACTION.SET_USER_INFO,
          userToken: userToken,
          role: userDataFromFB['userRole'],
          userPlan: userDataFromFB['planData'].userPlan,
          productPlans: userDataFromFB['planData'].allProductPlansSorted,
          adminGroup: userDataFromFB['adminGroup'],
          userGroups: userDataFromFB['userGroups'],
          projects: userDataFromFB['projects'],
          projData: utils.getProjectAlerts(userDataFromFB['projects']), // goes to usibilityReports.js
          workflows: userDataFromFB['templates'],
          userPrefs: userDataFromFB['preferences'],
          viewState: newViewState ?? viewState,
          assignments: utils.getAssignments(
            userDataFromFB['projects'],
            { [userToken.email]: true },
            userDataFromFB['assignedTaskIds'],
          ),
          // community: userDataFromFB['community'],
        });
      })
      .catch(error => {
        console.error(error);
      });
  };
}
