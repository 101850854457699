/* eslint-disable */

import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';
import { makeStyles, Chip, Typography, Card, CardContent } from '@material-ui/core';
import {
  setUserColleguePref,
  updateViewState,
  saveCurrentProject,
  handleMenu,
  dispatchUpdateViewState,
} from '../../data/actions/userInterface.js';
import * as NAV from '../../constants/navigation.js';
import StatusButton from '../styled_components/StatusButton.js';
import { dispatchGetAssignments } from '../../utils/generalUtilities.js';
import * as STATUS from '../../constants/status';
const NOT_ASSIGNED = 'not assigned';
const NOT_ASSIGNED_BKGD_COLOR = '#A0A0A020';
const assignedToAvatarSize = 25;
import Tooltip from '@material-ui/core/Tooltip';
import Person from '@material-ui/icons/Person';

const useStyles = makeStyles(theme => ({
  thinChipContainer: {
    marginTop: 8,
    marginBottom: 8,
    marginRight: 20,
    justifyContent: 'flex-start',
  },
  chip: {
    border: 'solid 1px #C250E2A0',
    background: '#C250E225',
    // background: 'none',
  },
  urProjectRow: {
    // flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  attentionMessage: {
    // margin: theme.spacing(2),
    color: '#888',
  },
  assignTaskText: {
    flex: 1,
    fontSize: '1.2rem',
    marginLeft: '0px',
    marginRight: '20px',
    marginTop: '0px',
    marginBottom: '10px',
    color: '#555',
    cursor: 'pointer',
    lineHeight: 1.3,
    '&:hover': {
      color: '#3c8cba',
      textDecoration: 'underline',
    },
  },
  assignmentContext: {
    flex: 1,
    fontSize: '0.9rem',
    marginLeft: '20px',
    marginTop: '-2px',
    marginBottom: '5px',
    // fontWeight: 600,
    color: '#777',
  },
  showContext: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.9rem',
    fontStyle: 'italic',
    fontWeight: 700,
    marginLeft: '10px',
    marginTop: '12px',
    marginBottom: '5px',
    color: '#777',
    borderStyle: 'solid',
    borderColor: '#666',
    borderWidth: 1,
    borderRadius: 10,
    width: 20,
    height: 20,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    justifyContent: 'space-between',
  },
  card: {
    margin: '10px',
    boxShadow: '4px 4px 4px 0 rgba(138, 148, 159, 0.7)',
    // border: `solid 1.px ${theme.palette.workCard.projectBorder}`,
    border: `solid 1px #444`,
    borderRadius: '11px',
    paddingBottom: '0',
    cursor: 'pointer',
    zIndex: 5,
  },
  assignedToText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 700,
    alignSelf: 'center',
  },
  assignedToAvatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10px',
    marginRight: '20px',
    borderRadius: assignedToAvatarSize / 2,
    height: assignedToAvatarSize,
    width: assignedToAvatarSize,
  },
  notAssignedTo: {
    color: '#A0A0A040',
  },
}));

const DraggableAssignmentCard = ({
  assignment,
  index,
  viewState,
  showAssignment = false,
  setEditor = null,
  userPrefs,
  setUserColleguePref,
  dispatchUpdateViewState,
  dispatchGetAssignments,
}) => {
  const [assignTaskHoverId, setAssignTaskHoverId] = React.useState(null);

  const handleDoubleClick = e => {};
  const buttonColor = '#F00';
  const classes = useStyles({
    buttonColor,
  });

  const handleSelection = (workflow, index, e) => {
    if (e) e.stopPropagation(); // Necessary to avoid event bubbling up to parent column
  };

  let newColorAssignment = null;
  useEffect(() => {
    if (newColorAssignment) {
      setUserColleguePref(newColorAssignment);
    }
  });
  const avatarId = assignment.taskObj.assignedTo
    ? assignment.taskObj.assignedTo.substring(0, 1).toUpperCase()
    : null;
  let collegueColor;
  if (assignment.taskObj.assignedTo) {
    if (userPrefs.collegues[assignment.taskObj.assignedTo]) {
      collegueColor = userPrefs.collegues[assignment.taskObj.assignedTo].color;
    } else {
      collegueColor = utils.randColor();
    }
  }
  return (
    <Draggable
      key={`wc-${assignment.taskId}`}
      draggableId={assignment.taskId}
      draggable={true}
      isDraggable={true}
      index={index}
      shouldRespectForceTouch={false}
    >
      {(dragProvided, dragSnapshot) => (
        <div onDoubleClick={handleDoubleClick}>
          <Card
            key={`card-${assignment.taskId}`}
            id={assignment.taskId}
            ref={dragProvided.innerRef}
            onClick={e => {}}
            {...dragProvided.draggableProps}
            {...dragProvided.dragHandleProps}
            className={classes.card}
            variant={'outlined'}
          >
            <CardContent
              className={classes.cardContent}
              style={{ backgroundColor: assignment.overdue ? '#FEF7F8' : undefined }}
            >
              <div className={classes.thinChipContainer}>
                <Chip label={assignment.taskObj.refId} className={classes.chip} />
              </div>
              <div
                className={classes.urProjectRow}
                style={{
                  marginTop: 10,
                  flexDirection: 'column',
                  flex: 1,
                }}
                key={index}
              >
                <Typography
                  onClick={e => {
                    Object.assign(viewState, {
                      curWindow: NAV.BOARD_DETAIL,
                      prevWindow: NAV.BOARD,
                      wbsStack: [
                        {
                          screen: NAV.BOARD,
                          item: {
                            d: assignment.delIndex,
                            w: 0,
                            t: 0,
                          },
                          level: 'p',
                        },
                        {
                          screen: NAV.BOARD_DETAIL,
                          item: {
                            d: assignment.delIndex,
                            w: assignment.workpackageIndex,
                            t: 0,
                          },
                          level: 'w',
                        },
                        {
                          screen: NAV.BOARD_DETAIL,
                          item: {
                            d: assignment.delIndex,
                            w: assignment.workpackageIndex,
                            t: assignment.taskIndex,
                          },
                          level: 't',
                        },
                      ],
                      currentProjectType: NAV.TYPE_PROJECT,
                      currentProjectIndex: assignment.projectIndex,
                      curProjectUUID: assignment.projectId,
                      detailsTabIndex: 0,
                      currentDeliverable: assignment.delIndex, // need to map to index here
                      currentWorkPackage: assignment.workpackageIndex,
                      currentTask: assignment.taskId,
                    });
                    dispatchUpdateViewState(viewState);
                  }}
                  variant={'h6'}
                  className={classes.assignTaskText}
                >
                  {assignment.task}
                </Typography>

                {assignTaskHoverId === assignment.taskId && (
                  // <Typography variant={'h8'} className={classes.assignmentContext}>
                  <Typography className={classes.assignmentContext}>
                    {`${assignment.project} / ${assignment.deliverable} / ${assignment.workpackage}`}
                  </Typography>
                )}
              </div>
              {showAssignment && avatarId && (
                <Tooltip title={assignment.taskObj.assignedTo}>
                  <div className={classes.assignedToAvatar} style={{ backgroundColor: collegueColor }}>
                    <Typography
                      className={classes.assignedToText}
                      onClick={e => {
                        // if(r.type !== 'project') {  // can't change project assignment here... always assigned to project manager
                        if (setEditor) {
                          setEditor({
                            mode: 'person',
                            point: [e.pageX - 280, e.pageY - 200],
                            item: assignment.taskObj,
                          });
                        }
                        // }
                      }}
                    >
                      {avatarId}
                    </Typography>
                  </div>
                </Tooltip>
              )}
              {showAssignment && !avatarId && (
                <div
                  className={classes.assignedToAvatar}
                  style={{ backgroundColor: NOT_ASSIGNED_BKGD_COLOR }}
                >
                  <Person
                    className={classes.notAssignedTo}
                    onClick={e => {
                      // if(r.type !== 'project') {  // can't change project assignment here... always assigned to project manager
                      if (setEditor) {
                        setEditor({
                          mode: 'person',
                          point: [e.pageX - 280, e.pageY - 200],
                          item: assignment.taskObj,
                        });
                      }
                      // }
                    }}
                  />
                </div>
              )}
              <div style={{ marginTop: 8, maxHeight: 58 }}>
                <StatusButton
                  task={assignment.taskObj}
                  project={assignment.projectObj}
                  onChangeFn={() => {
                    if (assignment.taskObj.status === STATUS.DONE) {
                      // if (assignment.overdue && assignment.taskObj.status === STATUS.DONE) {
                      dispatchGetAssignments();
                    }
                    return;
                  }}
                />
              </div>
              <div
                className={classes.showContext}
                onMouseOver={e => {
                  setAssignTaskHoverId(assignment.taskId);
                }}
                onMouseOut={e => {
                  setAssignTaskHoverId(null);
                }}
              >
                {`i`}
              </div>
            </CardContent>
          </Card>
        </div>
      )}
    </Draggable>
  );
};

export default connect(
  state => {
    return {
      projects: state.projects,
      viewState: state.viewState,
      userPrefs: state.userPrefs,
      user: state.user,
    };
  },
  {
    saveCurrentProject,
    updateViewState,
    handleMenu,
    setUserColleguePref,
    dispatchUpdateViewState,
    dispatchGetAssignments,
  },
)(DraggableAssignmentCard);
