/* eslint-disable */
import './App.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { makeStyles, Box, Typography } from '@material-ui/core';
import sizeof from 'firestore-size'; // max doc size is 1MB = 1,048,576 bytes
const MAX_DOC_SIZE = 1000000;

// Project action creators
import * as MO from './miniOrangeCred';
import { loadUserInfo, touchUserEmail, clearUserInfo, dispatchSignOutUser } from './data/actions/app';
import { getUserGroupIDs } from './data/actions/firebase/userGroups';
import { filterTemplatesByGroup } from './data/actions/firebase/userTemplates';
import { updateAnalytics, dispatchSaveSession, checkSessionState } from './data/actions/analytics';
import {
  updateProjects,
  updateWorkflows,
  tellUserDocChanged,
  handleTaskChange,
  handleProjectInfoChange,
  handleWorkPackageChange,
  handleYes,
  handleNo,
  handleMenu,
  dispatchUpdateViewState,
} from './data/actions/userInterface';

// Project components
import Alert from './components/Alert';
import FormDialog from './components/FormDialog';
import SignIn from './components/SignIn';

// New components
import Body from './components/Body';
import PAAppBar from './components/PAAppBar';

//Project database and utilities
import * as utils from './utils/generalUtilities.js';
import * as schema from './data/projectSchema.js';

// constants
import * as ANALYTICS from './constants/analytics';
import * as constants from './constants/app';
import * as NAV from './constants/navigation';

const theme = createMuiTheme({
  palette: {
    primary: {
      // shades of grey
      appbar: '#f6f6f6',
      light: '#fafafa',
      main: '#f5f5f5',
      // main: '#ddd',
      dark: '#cacaca',
      // contrastText: '#212121',
      contrastText: '#000000',
      addButton: '#99ccc4',
      infoButton: '#bbb',
      expandButton: '#FFF',
    },
    secondary: {
      // shades of teal
      appbar: '#50e2c210',
      light: '#EAFCF8',
      faint: '#52d1b518',
      main: '#52d1b5',
      menu: '#52d1b5b0',
      assign: '#52b5d130',
      // assign: '#52d1b540',
      // main: '#50e2c2',
      // menu: '#50e2c2b0',
      dark: '#00afa9',
      verydark: '#00736f',
      contrastText: '#000000',
      addButton: '#52d1b5',
      infoButton: '#52d1b5',
      expandButton: '#52d1b5',
    },
    badge: {
      main: '#ff0000',
    },
    workCard: {
      // startedWBS: '#ffffff',
      // startedBorderWBS: '#ccccca',
      startedWBS: '#fcfaea',
      startedBorderWBS: '#e0decc',
      started: '#fcfaea',
      startedBorder: '#e0decc',
      // started: '#ffffff',
      // startedBorder: '#ccccca',
      notStarted: '#fafafa',
      notStartedBorder: '#c2c2c2',
      // notStarted: '#ffffff',
      // notStartedBorder: '#ccccca',
      // started: '#f5f5f5',
      // startedBorder: '#c2c2c2',
      completed: '#EAFCF8',
      completedBorder: '#e6f7f2',
      selectedBorder: '#52d1b5',
      projectBorder: '#888',
      wbsColumn: '#fdfdfd',
      wbsColumnBorder: '#c2c2c2',
      wbsColumnTopStarted: '#fcf5c0',
      wbsColumnTopNotStarted: '#d4d4d4',
      wbsColumnTopCompleted: '#b6faeb',
      wbsColumnBorderStarted: '#e8dc84',
      wbsColumnBorderNotStarted: '#bfbfbf',
      wbsColumnBorderCompleted: '#68dec4',
    },
    text: {
      primary: '#111111',
      secondary: '#222',
      notStarted: '#888',
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
        fontWeight: 600,
        // color: "red",
        backgroundColor: '#888',
      },
    },
  },
});

const projectStyles = makeStyles(theme => ({
  progress: {
    // margin: theme.spacing(2),
  },
  root: {
    width: '100%',
  },
  formControl: {
    marginTop: 5,
    minWidth: 300,
    padding: 20,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  buttonList: {
    width: 64,
    height: 64,
    paddingTop: 50,
  },
  button: {
    margin: theme.spacing(0),
    width: '100%',
    overflowX: 'auto',
  },
  paper: {
    margin: 10,
    width: '100%',
    overflowX: 'auto',
    padding: 20,
  },
  inputLabel: {
    padding: 30,
    margin: 20,
  },
  table: {
    marginTop: 10,
    marginBottom: 10,
    minWidth: 650,
  },
  container: {
    margin: 10,
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
  loadingText: {
    fontSize: '1em',
    fontWeight: 500,
    color: '#60606080',
  },
}));

const IS_DEV = process.env.REACT_APP_PROJECT_ID !== 'project-534d9';
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.lastAppState = 'closed';
    this.curProjectListener = null;
    this.curWorkflowSetListener = null;
    this.curAnalyticsListener = null;
    this.sessionCheckTimer = null;
  }

  componentDidCatch(error, errorInfo) {
    utils.getCurrentProjectOrWorkflow(this.props).corruptData = { error: error, info: errorInfo };
    console.error(`caught rendering error in App: Error: ${error}, Info: ${errorInfo}`);
    this.setState({ hasError: true }); // required to rerender in order to avoid getDerivedStateFromError()
  }

  handleBrowserFocus = e => {
    // happens when user selects browser and PA is active tab, or select PA tab when browser prev active... basically resuming the app
    console.log('============================== App state: active, prev state: ' + this.lastAppState);
    this.lastAppState = 'active';

    this.props.checkSessionState();
    clearInterval(this.saveSessionTimer);
    this.saveSessionTimer = setInterval(
      this.props.dispatchSaveSession,
      ANALYTICS.CHECK_SAVE_SESSION_INTERVAL,
    );
  };

  handleBrowserBlur = e => {
    // happens when user selects other app (leaving browser) or selects other tab in browser or when deleting Tooday tab (basically ending the app)
    console.log('============================== App state: background, prev state: ' + this.lastAppState);
    this.lastAppState = 'background';

    this.props.dispatchSaveSession(true);
    clearInterval(this.saveSessionTimer);
    this.saveSessionTimer = null;
  };

  render() {
    const curLocation = window.location;
    const curSearchStrings = curLocation.search;
    // console.log(`++++++++++++++ url search: ${curSearchStrings}`)

    if (!this.props.firebase.isLive) {
      // waiting for firebase.auth to define user state
      // return null; // should be brief - one render cycle hopefully
      return (
        <Box
          display="flex"
          width={'100vw'}
          height={'100vh'}
          // bgcolor="lightblue"
        >
          <Box m="auto">
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '20vh',
              }}
            >
              <Typography component="h2" variant="h5" className={projectStyles.loadingText}>
                One moment, loading your projects...
              </Typography>
              <CircularProgress style={{ color: '#52d1b570', marginTop: '25px' }} size={60} />
            </Box>
          </Box>
        </Box>
      );
    } else {
      if (!this.props.user.email) {
        return (
          <MuiThemeProvider theme={theme}>
            <SignIn />
          </MuiThemeProvider>
        );
      } else {
        if (!this.props.projects) {
          console.log('waiting for projects to load user');
          return (
            <Box
              display="flex"
              width={'100vw'}
              height={'100vh'}
              // bgcolor="lightblue"
            >
              <Box m="auto">
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '20vh',
                  }}
                >
                  <Typography component="h2" variant="h5" className={projectStyles.loadingText}>
                    One moment, loading your projects...
                  </Typography>
                  <CircularProgress style={{ color: '#52d1b570', marginTop: '25px' }} size={60} />
                </Box>
              </Box>
            </Box>
          );
        } else {
          const curProjectOrWorkflow = utils.getCurrentProjectOrWorkflow(this.props);
          if (!curProjectOrWorkflow) {
            // trap for bad data config
            console.error(`no project/workflow found in App.js - logging out to reset`);
            this.props.dispatchSignOutUser();
            return null;
          }
          if (curProjectOrWorkflow.version > schema.SCHEMA_VERSION) {
            // simple trap for out of date app with newer data version - block until user updates app
            // could be improved to auto load for the user with a press of a button, but need to think
            // through UX before implementing too much here.  Alternatively can improve style/look at a min
            return (
              <h3 style={{ width: 600, margin: 'auto', marginTop: 100 }}>
                This version of the app is too old to load your projects from the server. Please hold down the
                Shift key and press the page reload button to update to the latest version of the app.
              </h3>
            );
          } else {
            let percentageComplete;
            if (this.props.viewState.currentProjectType === NAV.TYPE_PROJECT) {
              try {
                percentageComplete = utils.percentageProjectTasksYes(curProjectOrWorkflow);
              } catch (error) {
                curProjectOrWorkflow.corruptData = { error: error, info: 'Bad data in App.js' };
              }
            }

            if (curProjectOrWorkflow.corruptData && !this.props.viewState.showingAnalytics) {
              console.error(`Cannot load project due to error: ${curProjectOrWorkflow.corruptData.error}`);
              console.log(curProjectOrWorkflow.corruptData.info);
              // console.trace()
              return (
                <MuiThemeProvider theme={theme}>
                  <Alert
                    open={this.props.viewState.alert}
                    title={this.props.viewState.title}
                    alertYesButton={this.props.viewState.alertYesButton}
                    text={this.props.viewState.text}
                    answerYes={this.props.handleYes}
                    answerNo={this.props.handleNo}
                  />
                  <PAAppBar
                    project={curProjectOrWorkflow}
                    percentageComplete={percentageComplete ? percentageComplete : 0}
                    user={this.props.user}
                    docListenerParent={this}
                  />
                  <div style={{ textAlign: 'center' }}>
                    <h3>
                      We cannot load this project (
                      {curProjectOrWorkflow.name
                        ? `"${curProjectOrWorkflow.name}" - id: ${curProjectOrWorkflow.id}`
                        : 'name unknown'}
                      ).
                    </h3>
                    <h3>
                      Please contact the developer if you want to restore this document or you can delete this
                      project from your list
                    </h3>
                    <h3
                      style={{ color: 'darkred' }}
                    >{`Error: ${curProjectOrWorkflow.corruptData.error}, Info: ${curProjectOrWorkflow.corruptData.info}`}</h3>
                  </div>
                </MuiThemeProvider>
              );
            } else {
              return (
                <MuiThemeProvider theme={theme}>
                  <Alert
                    open={this.props.viewState.alert}
                    title={this.props.viewState.title}
                    alertYesButton={this.props.viewState.alertYesButton}
                    text={this.props.viewState.text}
                    answerYes={this.props.handleYes}
                    answerNo={this.props.handleNo}
                  />
                  <FormDialog
                    key={this.props.viewState.text}
                    open={this.props.viewState.form}
                    title={this.props.viewState.title}
                    text={this.props.viewState.text}
                    placeholder={this.props.viewState.placeholder}
                    formType={this.props.viewState.formType}
                    textLabel={this.props.viewState.textLabel}
                    workflows={this.props.workflows}
                    curProject={curProjectOrWorkflow}
                    answerYes={this.props.handleYes}
                    answerNo={this.props.handleNo}
                    classes={projectStyles}
                    viewState={this.props.viewState}
                  />
                  <Body
                    user={this.props.user}
                    projectOrWorkflow={curProjectOrWorkflow}
                    percentageComplete={percentageComplete}
                    workflows={this.props.workflows}
                    handleProjectInfoChange={this.props.handleProjectInfoChange}
                    showingAnalytics={this.props.viewState.showingAnalytics}
                    docListenerParent={this}
                    // viewState={this.props.viewState}
                  />
                </MuiThemeProvider>
              );
            }
          }
        }
      }
    }
  }
  // getJWTExpireDate(jwtToken) {
  //   if (jwtToken) {
  //     try {
  //       const [, payload] = jwtToken.split('.');
  //       const { exp: expires } = JSON.parse(window.atob(payload));
  //       if (typeof expires === 'number') {
  //         return new Date(expires * 1000);
  //       }
  //     } catch {
  //       // ignore
  //     }
  //   }
  //   return null;
  // }
  // sso_redirect = (user, source) => {
  //   const getRLInfo = this.props.firebase.functions.httpsCallable('getRLInfo');
  //   const userInfo = {
  //     id: user.uid,
  //     email: user.email,
  //     ts: new Date().getTime(),
  //   };
  //   const userInfoStr = JSON.stringify(userInfo);
  //   if (IS_DEV) console.log(`=======  encoding User Info: ${userInfoStr} ${source}`);
  //   try {
  //     let inProcessing = false;
  //     getRLInfo({
  //       string: userInfoStr,
  //       source: source,
  //     })
  //       .then(result => {
  //         inProcessing = true;
  //         if (IS_DEV) console.log(`=======  redirecting to ${result.data.redirectURL}`);
  //         window.location = `${result.data.redirectURL}/?id=${result.data.encodedString}`;
  //       })
  //       .catch(error => {
  //         if (IS_DEV)
  //           console.log(
  //             `=======  getRLInfo failed ${inProcessing ? 'during processing' : ''} ${userInfoStr}: ${error}`,
  //           );
  //       });
  //   } catch (error) {
  //     if (IS_DEV) console.log(`=======  sso redirect failed ${userInfoStr}: ${error}`);
  //   }
  // };

  componentDidMount() {
    // firebase controls whether user is signed in or not
    // but we need to hold the user state in the (redux) store

    if (IS_DEV) {
      console.log(`+++++++++++++++++++++++++++++++++++++++++++++`);
      console.log(`+++++ starting App ${window.location.search}`);
      console.log(`+++++++++++++++++++++++++++++++++++++++++++++`);
    }

    // next bit of code used to clean up analytics docs
    // const deletedDocs = [];
    // const updatedDocs = [];
    // this.props.firebase.db
    //   .collection('analytics')
    //   .get()
    //   .then(querySnapshot => {
    //     querySnapshot.forEach(doc => {
    //       let docData = doc.data();
    //       docData.id = doc.id;
    //       const weekMoment = moment(docData.weekId, "GGGG-WW")
    //       const newWeekId = weekMoment.format("GGGG-WW")
    //       if(weekMoment.isBefore(moment().subtract(6, 'months'))) {
    //         deletedDocs.push(docData)
    //       } else {
    //         if(newWeekId !== docData.weekId) {
    //           docData.weekId = newWeekId;
    //           updatedDocs.push(docData);
    //         }
    //       }
    //     });
    //     // setTimeout(() => {
    //       updatedDocs.forEach(doc => {
    //         this.props.firebase.db
    //           .collection('analytics')
    //           .doc(doc.id)
    //           .set(doc)
    //           .then(result => {
    //             console.log(`updated old doc id ${doc.id} with new weekId ${doc.weekId}`)
    //           })
    //           .catch(err => {
    //             throw new Error(`issue updated old doc id ${doc.id} with new weekId ${newWeekId}: ${err}`)
    //           })
    //       })
    //     // },0)

    //     deletedDocs.forEach(doc => {
    //       this.props.firebase.db
    //         .collection('analytics')
    //         .doc(doc.id)
    //         .delete()
    //         .then(result => {
    //           console.log(`deleted old doc id ${doc.id} for week ${doc.weekId}`)
    //         })
    //         .catch(err => {
    //           throw new Error(`issue deleting olf analytics doc id ${doc.id} for week ${newWeekId}: ${err}`)
    //         })
    //     })
    //   })
    //   .catch(err => {
    //     throw new Error(`issue retrieving analytics doc info: ${err}`)
    //   })

    if (IS_DEV) console.log(`setting up Firebase state change handler`);
    this.props.firebase.auth.onAuthStateChanged(user => {
      if (user) {
        // normal login handling
        if (this.props.app.forceLogout) {
          console.log(`Forcing user signout: ${user.email}`);
          this.props.firebase.auth.signOut(); // loop back to else case
        } else {
          console.log(`User signed in: ${user.email}`);
          this.props.touchUserEmail(user.email);
          this.props.loadUserInfo(user);
          this.props.checkSessionState();
        }
        // handle session time out etc
      } else {
        // user is logged out
        clearInterval(this.saveSessionTimer);
        this.saveSessionTimer = null;
        console.log(`User signed out`);
        this.props.clearUserInfo();
      }
      const curQueryStrings = window.location.search.substring(1);
      const stringBits = curQueryStrings.split('&');
      const queryStringByKey = {};
      stringBits.forEach(string => {
        const valueBits = string.split(/=(.+)/); //  TODO make this more robust, because it may fail if parameters contain multiple =
        if (valueBits.length >= 2) {
          queryStringByKey[valueBits[0]] = valueBits[1];
        }
      });

      const accountPageRedirect = queryStringByKey['acc']; // redirect back from Strip
      if (accountPageRedirect) {
        const newViewState = Object.assign({ ...this.props.viewState }, { curWindow: NAV.ACCOUNT });
        this.props.dispatchUpdateViewState(newViewState);
      }
    });
    if (this.props.app.forceLogout) {
      console.log(`Forcing user signout`);
      this.props.firebase.auth.signOut(); // will respond via FB onAuthStateChanged() above and trigger signout SSO
    }
    window.addEventListener('beforeunload', this.handleBrowserBlur);
    window.addEventListener('focus', this.handleBrowserFocus);
    window.addEventListener('blur', this.handleBrowserBlur);
    window.addEventListener('beforeunload', this.handleBrowserBlur);
    checkSessionState(true); // 1st time, look if prev session expired
    this.sessionCheckTimer = setInterval(3000, () => {
      checkSessionState(true); // regularly check if prev session expired, in case app left open in forground
    });
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleBrowserBlur);
    window.removeEventListener('focus', this.handleBrowserFocus);
    window.removeEventListener('blur', this.handleBrowserBlur);
    window.removeEventListener('beforeunload', this.handleBrowserBlur);
    clearInterval(this.sessionCheckTimer);
    this.sessionCheckTimer = null;
  }

  adjustProjectsWithNewTemplates(projects, templates) {
    const defaultTemplate = utils.getDefaultTemplate(templates);
    if (!defaultTemplate) throw new Error(`no default workflow defined for library`);

    const templateLookup = {};
    Object.keys(templates).forEach(setId => {
      templates[setId].forEach(template => {
        templateLookup[template.uuid] = template;
      });
    });
    projects.forEach(project => {
      if (
        !project.template &&
        (!templateLookup[project.templateRefUUID] ||
          templateLookup[project.templateRefUUID].library.level === 0)
      ) {
        project.templateRefUUID = defaultTemplate.uuid;
      }
    });
    return projects;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      firebase,
      projects,
      workflows,
      group,
      viewState,
      tellUserDocChanged,
      user,
      updateProjects,
      updateWorkflows,
      updateAnalytics,
      checkSessionState,
    } = this.props;

    //debugger;

    const thisWeekId = moment().format('GGGG-WW');

    if (
      // false && // temp disable analytics
      firebase.isLive && // app has booted
      user.email &&
      (!this.curAnalyticsListener || this.curAnalyticsListener.id !== thisWeekId)
    ) {
      if (this.curAnalyticsListener) {
        // new week detected
        console.log(`stopping existing analytics listener`);
        this.curAnalyticsListener.stopListener();
      }
      console.log(`setting analytics listener`);
      const analyticsSetStopListener = firebase.db
        .collection('analytics')
        .where('weekId', '==', thisWeekId)
        .onSnapshot(
          querySnapshot => {
            console.log(`====== in curAnalyticsListener.querySnapshot`);

            // if(!user.uid) return;   // if user signed out block any trailing update processing
            if (querySnapshot.size > 0) {
              let cnt = 0;
              console.log(`++++++++  analytics listener: updating analytics ${querySnapshot.length}`); // temp to track jerky UI at login

              querySnapshot.forEach(docRef => {
                if (cnt++ === 0) {
                  const data = { docId: docRef.id, ...docRef.data() };
                  updateAnalytics(data); // should only be one document in the querySnapshot
                }
              });
            } else {
              // new analytics week doc
              const newAnalyticsWeekDoc = {
                weekId: thisWeekId,
                records: {},
              };
              console.log(`firebase.db.set - curAnalyticsListener`);

              firebase.db
                .collection('analytics')
                .add(newAnalyticsWeekDoc)
                .then(docRef => {
                  // updateAnalytics(newAnalyticsWeekDoc);   // this will be caught by listener above
                })
                .catch(function(err) {
                  console.error(`Cannot generate analytics doc: ${err}`); // rare chance that someone else created this at same time
                  updateAnalytics(newAnalyticsWeekDoc);
                });
            }
          },
          error => {
            if (this.curAnalyticsListener) {
              this.curAnalyticsListener.stopListener();
              this.curAnalyticsListener = null;
            }
            console.log(`cleaning up Analytics Listener: ${error}`);
          },
        );
      this.curAnalyticsListener = {
        id: thisWeekId,
        stopListener: analyticsSetStopListener,
      };
    }

    if (
      false &&
      firebase.isLive && // app has booted
      workflows && // workflow docs exists
      // !this.curWorkflowSetListener
      (!this.curWorkflowSetListener || this.curWorkflowSetListener.groups !== user.groups)
    ) {
      if (this.curWorkflowSetListener) {
        console.log(`stopping existing Template set listener`);
        this.curWorkflowSetListener.stopListener();
      }
      console.log(`setting Template set listener`);
      let groupIDArray = getUserGroupIDs(this.props.user.groups, this.props.user.role);
      groupIDArray.forEach(idVal => {
        console.log(`   fetching group id: ${idVal}`);
      });
      if (groupIDArray.length > 10) {
        console.error(`  in App.js this user belongs to more than 10 groups - limiting groups to first 10`);
        // alert(`This user belongs to more than 10 groups - limiting groups to first 10`);
        groupIDArray = groupIDArray.slice(0, 10);
      }
      const workflowSetStopListener = firebase.db
        .collection('workflows')
        .where('deletedDate', '==', null)
        .where('workflowSetId', 'in', groupIDArray)
        .onSnapshot(
          querySnapshot => {
            console.log(`====== in curWorkflowSetListener.querySnapshot`);
            if (!user.uid) return; // if user signed out block any trailing update processing
            if (querySnapshot.size > 0) {
              const templates = [];
              let defaultFound = false;
              console.log(`++++++++  workflow listener: updating ${querySnapshot.size} workflows`); // temp to track jerky UI at login

              querySnapshot.forEach(templateDoc => {
                const templateData = templateDoc.data();
                // console.log(`fetched template - ${templateData.name} ${templateData.workflowSetId}`);
                templateData.id = templateDoc.id;
                if (!templateData.deletedDate) {
                  console.log(`++++++++  workflow listener: migrating workflow ${templateData.name}`); // temp to track jerky UI at login
                  templates.push(schema.migrateTemplateSchema(templateData));

                  if (sizeof(templateData) > 0.95 * MAX_DOC_SIZE) {
                    setTimeout(() => {
                      alert(
                        `Critical Warning: you are accessing a workflow (${templateData.name}) that is close to the maximum project size.  Please reduce it's size to avoid storage issues.`,
                      );
                    }, 10);
                  }

                  if (
                    templateData.workflowSetId === constants.PUBLIC_GROUP_ID &&
                    templateData.library.default
                  ) {
                    defaultFound = true;
                  }
                }
              });
              if (defaultFound) {
                let { filteredTemplates, existingTemplateIds, existingSetIds } = filterTemplatesByGroup(
                  this.props.user.groups,
                  templates,
                  user.email,
                );
                existingSetIds.forEach(setId => {
                  filteredTemplates[setId].sort((p1, p2) =>
                    p1.library.order < p2.library.order ? -1 : p1.library.order === p2.library.order ? 0 : 1,
                  );
                });
                utils.setSmartTemplateIndent(filteredTemplates);
                updateWorkflows(filteredTemplates);

                if (projects) {
                  // patch project.componentLibraryUUID's that are no longer valid
                  updateProjects(
                    this.adjustProjectsWithNewTemplates(this.props.projects, filteredTemplates),
                    null,
                    user.email,
                  );
                }
              }
            }
          },
          error => {
            if (this.curWorkflowSetListener) {
              this.curWorkflowSetListener.stopListener();
              this.curWorkflowSetListener = null;
            }
            console.log(`cleaning up Template set Listener: ${error}`);
          },
        );
      this.curWorkflowSetListener = {
        id: constants.PUBLIC_GROUP_ID,
        groups: user.groups,
        stopListener: workflowSetStopListener,
      };
    }

    if (
      firebase.isLive && // app has booted
      projects && // projects are loaded
      (!this.curProjectListener || this.curProjectListener.id !== utils.getCurrentProject(this.props).id)
    ) {
      if (this.curProjectListener) {
        this.curProjectListener.stopListener();
      }
      let curProject = utils.getCurrentProject(this.props);

      console.log;
      const stopListener = firebase.db
        .collection('projects')
        .doc(curProject.id)
        .onSnapshot(
          snapshot => {
            console.log(`====== in curProjectListener.onSnapshot`);
            const newData = snapshot.data();
            if (newData) {
              console.log(`++++++++  projects listener: updating 1 projects`); // temp to track jerky UI at login

              console.log(
                `++++++++  projects listener: project cloud and local store updated.. ${
                  this.curProjectListener.updateCount ? this.curProjectListener.updateCount : 'new'
                }`,
              );
              schema.migrateProjectSchema(newData);

              if (sizeof(newData) > 0.95 * MAX_DOC_SIZE) {
                setTimeout(() => {
                  alert(
                    `Critical Warning: your current project is close to the maximum project size.  Please reduce it's size, possibly splitting it into 2 or more subprojects.`,
                  );
                }, 10);
              }
              // if not defined, listener has been shut off
              if (
                this.curProjectListener.updateCount > 0 &&
                newData.lastModifiedBy !== user.email &&
                newData.lastModifiedBy !== 'not set' && // these are defaults that need to be updated
                newData.lastModifiedBy !== 'a coworker'
              ) {
                // if changes by other user, wait for user to confirm before applying
                tellUserDocChanged(viewState, snapshot.data());
              } else {
                curProject = utils.getCurrentProject(this.props);
                if (newData.id === curProject.id) {
                  curProject = newData;
                  updateProjects(this.props.projects, null, user.email);
                }
              }
              this.curProjectListener.updateCount++;
            } else {
              console.log('ignoringproject cloud and local store update - no data');
            }
          },
          error => {
            if (this.curProjectListener) {
              this.curProjectListener.stopListener();
              this.curProjectListener = null;
            }
            console.log(`cleaning up Project Listener: ${error}`);
          },
        );
      this.curProjectListener = {
        id: curProject.id,
        stopListener: stopListener,
        updateCount: 0,
      };
      // console.log('')
      // console.log(`+++++++++++++++++++++++++++++++ curProjectListener : ${this.curProjectListener ? this.curProjectListener.id : 'not set'}`)
    }
  }
}

export default connect(
  state => {
    return {
      app: state.app,
      firebase: state.firebase,
      user: state.user,
      projects: state.projects,
      workflows: state.workflows,
      viewState: state.viewState,
      sso: state.sso,
    };
  },
  {
    updateProjects,
    updateWorkflows,
    updateAnalytics,
    dispatchSaveSession,
    dispatchUpdateViewState,
    tellUserDocChanged,
    checkSessionState,
    touchUserEmail,
    loadUserInfo,
    dispatchSignOutUser,
    clearUserInfo,
    handleTaskChange,
    handleProjectInfoChange,
    handleWorkPackageChange,
    handleYes,
    handleNo,
    handleMenu,
  },
)(App);
